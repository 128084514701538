/*!
 * ol-geocoder - v4.1.1
 * A geocoder extension for OpenLayers.
 * https://github.com/jonataswalker/ol-geocoder
 * Built: Sat Oct 17 2020 15:56:40 GMT-0300 (Brasilia Standard Time)
 */
.ol-touch .ol-control.gcd-gl-control button{font-size:1.14em}
.ol-touch .ol-geocoder.gcd-gl-container{font-size:1.1em}
.ol-geocoder.gcd-gl-container{position:absolute;top:4.875em;left:.5em;box-sizing:border-box;font-size:.9em}
.ol-geocoder.gcd-gl-container *,.ol-geocoder.gcd-gl-container :after,.ol-geocoder.gcd-gl-container :before{box-sizing:inherit}
.ol-geocoder .gcd-gl-control{width:2.1875em;height:2.1875em;overflow:hidden;transition:width .2s,height .2s}
.ol-geocoder .gcd-gl-expanded{width:15.625em;height:2.1875em}
.ol-geocoder .gcd-gl-input{position:absolute;z-index:99;top:.25em;left:2.5em;width:14.84375em;padding:5px;border:1px solid #ccc;font-family:inherit;font-size:.875em;background-color:#fff;color:#222}
.ol-geocoder .gcd-gl-input:focus{border:none;outline:none;box-shadow:inset 0 0 0 1px #4d90fe,inset 0 0 5px #4d90fe}
.ol-geocoder .gcd-gl-reset{position:absolute;z-index:100;top:0;right:0;width:1.5625em;height:100%;line-height:1.4;border:none;background-color:transparent;display:inline-block;outline:0;cursor:pointer}
.ol-geocoder .gcd-gl-reset:after{content:"\d7";display:inline-block;color:#333;font-size:1.5em;cursor:pointer}
.ol-geocoder .gcd-gl-btn{position:absolute;width:1.5625em;height:1.5625em;top:.125em;left:.125em;background-image:url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAABPUlEQVQoU41SwXHCQAzUHh58eoUOIBWEDkI6oAToIKkg7iAuwakgpAIowXRACcnrzp6BzchjMx4wE/S6kW5XK60gvQghzJIkmVoqSZI9gJ9+/fINS5Cc1HX9QXIlIr/tpwcRyb33b7cIGnAIYQdg4pxbjcfj0nJ1Xc+Px+PGObdN03Q9RIAQwgpAnqbp7FKmjQGgJLlU1d2V7BjjRkQO3vvXIXarkyxVNbsCm2QR2Q0V7XOMMReRmfd+OQQubN6hYgs22ZtbnRcAtiRfLueqqmpJ8ovko6oeBq0KIWQA3gFkzrlmMafTaUEyI/mpqmbhVTRWWbRdbClPbeobQNES5KPRqOxs7DBn8K1DsAOKMZYApiTXqlrcDe4d0XN7jWeCfzt351tVle2iGalTcBd4gGDvvZ/fDe4RmCOFLe8Pr7mvEP2N9PQAAAAASUVORK5CYII=");background-repeat:no-repeat;background-position:50%}
.ol-geocoder ul.gcd-gl-result{position:absolute;top:2.1875em;left:2em;width:16.25em;max-height:18.75em;white-space:normal;list-style:none;padding:0;margin:0;background-color:#fff;border-radius:4px;border-top:none;border-top-left-radius:0;border-top-right-radius:0;overflow-x:hidden;overflow-y:auto;box-shadow:0 1px 7px rgba(0,0,0,.8);transition:max-height .3s ease-in}
.ol-geocoder ul.gcd-gl-result>li{width:100%;overflow:hidden;border-bottom:1px solid #eee;padding:0;line-height:.875rem}
.ol-geocoder ul.gcd-gl-result>li>a{display:block;text-decoration:none;padding:3px 5px}
.ol-geocoder ul.gcd-gl-result>li>a:hover{background-color:#d4d4d4}.ol-geocoder ul.gcd-gl-result>li:nth-child(odd){background-color:#e0ffe0}
.ol-geocoder ul.gcd-gl-result:empty{display:none}.ol-geocoder.gcd-txt-container{position:absolute;width:25em;height:4.375em;top:.5em;left:calc(50% - 12.5em);box-sizing:border-box}
.ol-geocoder.gcd-txt-container *,.ol-geocoder.gcd-txt-container :after,.ol-geocoder.gcd-txt-container :before{box-sizing:inherit}
.ol-geocoder .gcd-txt-control{position:relative;width:100%;height:4.375em;border:1px solid #ccc;background-color:#fff;overflow:hidden}
.ol-geocoder .gcd-txt-input{position:absolute;z-index:99;top:0;left:0;width:100%;height:100%;padding:5px 30px 5px 40px;border:none;text-indent:6px;background-color:transparent;font-family:inherit;font-size:.875em}
.ol-geocoder .gcd-txt-input:focus{outline:none;box-shadow:inset 0 0 0 1px #4d90fe,inset 0 0 6px #4d90fe}
.ol-geocoder .gcd-txt-reset{position:absolute;z-index:100;top:0;right:0;width:2.5em;height:100%;line-height:100%;border:none;background-color:transparent;display:inline-block;vertical-align:middle;outline:0;cursor:pointer}
.ol-geocoder .gcd-txt-reset:after{content:"\d7";display:inline-block;color:#333;font-size:2em;cursor:pointer}
.ol-geocoder .gcd-txt-glass{position:absolute;top:0;left:0;z-index:100;display:inline-block;width:2.5em;height:100%;background-size:1.38889em;background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32'%3E%3Cpath fill='%23333' d='M29.156 29.961l-.709.709a2.006 2.006 0 01-2.838 0l-5.676-5.674c-.656-.658-.729-1.644-.281-2.412l-3.104-3.102a9.975 9.975 0 01-5.965 1.979C5.043 21.461.552 16.97.552 11.43S5.043 1.398 10.583 1.398c5.541 0 10.031 4.491 10.031 10.032 0 2.579-.98 4.923-2.58 6.7l3.035 3.035c.768-.447 1.754-.375 2.41.283l5.676 5.674c.784.785.784 2.056.001 2.839zM18.088 11.389a7.524 7.524 0 00-7.524-7.523 7.523 7.523 0 10-.001 15.046 7.525 7.525 0 007.525-7.523z'/%3E%3C/svg%3E");background-repeat:no-repeat;background-position:50%}
.ol-geocoder ul.gcd-txt-result{position:absolute;top:4.575em;left:0;width:25em;max-height:18.75em;white-space:normal;list-style:none;padding:0;margin:0;background-color:#fff;border-radius:4px;border-top:none;border-top-left-radius:0;border-top-right-radius:0;overflow-x:hidden;overflow-y:auto;box-shadow:0 1px 7px rgba(0,0,0,.8);transition:max-height .3s ease-in}
.ol-geocoder ul.gcd-txt-result>li{width:100%;overflow:hidden;border-bottom:1px solid #eee;padding:0;line-height:.875rem}
.ol-geocoder ul.gcd-txt-result>li>a{display:block;text-decoration:none;padding:3px 5px}
.ol-geocoder ul.gcd-txt-result>li>a:hover{background-color:#d4d4d4}
.ol-geocoder ul.gcd-txt-result>li:nth-child(odd){background-color:#e0ffe0}
.ol-geocoder ul.gcd-txt-result:empty{display:none}
.ol-geocoder .gcd-hidden{opacity:0;visibility:hidden}
.ol-geocoder .gcd-pseudo-rotate:after{-webkit-animation:spin .7s linear infinite;animation:spin .7s linear infinite}@-webkit-keyframes spin{0%{transform:rotate(0deg)}to{transform:rotate(1turn)}}@keyframes spin{0%{transform:rotate(0deg)}to{transform:rotate(1turn)}}
.gcd-address,.gcd-road{font-size:.875em;font-weight:500;color:#333}.gcd-city{font-weight:400}.gcd-city,.gcd-country{font-size:.75em;color:#333}.gcd-country{font-weight:lighter}